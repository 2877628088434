<template>
  <v-dialog
    :value="true"
    max-width="600"
    @keydown.esc="close"
    @click:outside="close"
    scrollable
  >
    <v-card>
      <v-card-title class="text-h6">
        {{ $t('youHaveToSelectLocationToBlock') }}
      </v-card-title>
      <v-card-actions class="pb-4">
        <v-select
          dense
          hide-details
          :label="$t('location')"
          outlined
          v-model="selectedLocation"
          :items="locations"
          @change="onChange($event)"
          item-text="label"
          item-value="uuid"
        />
        <v-btn
          class="ml-4"
          color="blue darken-1"
          text
          @click="blockSlot"
        >
          {{ $t('blockSlot') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SelectLocationDialog",
  props: {
    locations: {
      type: Array, 
      default: () => []
    }
  },
  data() {
    return {
      selectedLocation: null
    }
  },
  methods: {
    onChange(event) {
      this.$emit('changeSelectedLocation', event)
    },
    close() {
      this.$emit('close')
    },
    blockSlot() {
      this.$emit('blockSlot')
    }
  }
}
</script>

<style scoped>

</style>
